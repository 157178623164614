import { useAuthor } from 'src/hooks/useAuthor';

import * as styles from './styles/Bio.module.scss';

interface Props {
  author: string;
}

export default function Bio({ author }: Props) {
  const { name, description, thumbnail } = useAuthor(author);
  return (
    <div className={styles.bio} data-testId="bio">
      <div className={styles.authorDescription}>
        <div style={{ backgroundImage: `url(${thumbnail})` }} className={styles.photo} data-testId="bio-image" />
        <div>
          <strong>{name}</strong>
          <p className={styles.desc}>{description}</p>
        </div>
      </div>
    </div>
  );
}
