import InnerHtml from 'dangerously-set-html-content';

interface Props {
  async: boolean;
  src: string;
  children: string[];
}
export default function Script({ children, ...props }: Props) {
  const { async, src } = props;
  const childText = children ? children[0] : '';

  return <InnerHtml html={`<script ${async ? 'async' : ''} ${src ? `src=${src}` : ''}>${childText}</script>`} />;
}
