import { Link } from 'gatsby';

import { BlogPageContext } from 'src/templates/blog-post';
import * as styles from './styles/PostNavigator.module.scss';

interface Props {
  pageContext: BlogPageContext;
}

const PostNavigator = ({ pageContext }: Props) => {
  const { previous, next } = pageContext;

  return (
    <div className={styles.container}>
      <div className={styles.header}>이어지는 글</div>
      <ul className={styles.navigator} data-testId="post-navigator">
        <li>
          {previous && (
            <Link to={previous.fields.slug} rel="prev">
              <span className={styles.tag}>이전 글</span>
              <p className={styles.postTitle}>{previous.frontmatter.title}</p>
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={next.fields.slug} rel="next">
              <span className={styles.tag}>다음 글</span>
              <p className={styles.postTitle}>{next.frontmatter.title}</p>
            </Link>
          )}
        </li>
      </ul>
    </div>
  );
};

export default PostNavigator;
