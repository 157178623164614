import { useState } from 'react';
import Iframe from 'react-iframe';

import * as styles from './styles/IframeOpener.module.scss';

interface Props {
  openertext?: string;
  url: string;
}
export default function IframeOpener({ openertext, url }: Props) {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <span
        role="presentation"
        onMouseEnter={() => setOpened(true)}
        onMouseLeave={() => setOpened(false)}
        className={styles.openerText}
      >
        {openertext}
      </span>
      {!!opened && (
        <Iframe url={url} width="640px" height="320px" position="absolute" className={styles.frame} display="block" />
      )}
    </>
  );
}
