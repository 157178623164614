import { useEffect, useRef, useState } from 'react';
import { PostTOCItem } from 'src/components/post/PostTOCItem';
import { HeaderNode, makeHeaderTree, observeElement } from 'src/utils/toc';
import * as styles from './styles/PostTOC.module.scss';

interface Props {
  headerNodeList: HeaderNode[];
}

export default function PostTOC({ headerNodeList }: Props) {
  const [rootNode, setRootNode] = useState<HeaderNode>();
  const [focusedHeaderIndex, setFocusedHeaderIndex] = useState(0);
  const windowInnerHeight = useRef(0);

  /**
   * 스크롤 시 자동으로 포커스 변경 로직
   */
  useEffect(() => {
    const observerList: IntersectionObserver[] = [];

    headerNodeList.forEach(({ id }, index) => {
      const target = document.getElementById(id);
      if (target) {
        const observer = observeElement(target, () => {
          setFocusedHeaderIndex(index + 1);
        });
        observerList.push(observer);
      }
    });

    return () => {
      observerList.forEach(observer => {
        observer.disconnect();
      });
    };
  }, [headerNodeList]);

  /**
   * height값이 변경될 경우 재설정 한다.
   */
  useEffect(() => {
    const listener = () => {
      windowInnerHeight.current = window.innerHeight;
    };

    window.addEventListener('resize', listener);
    listener();

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  /**
   * 자료구조 생성
   */
  useEffect(() => {
    const root = makeHeaderTree(headerNodeList);
    setRootNode(root);
  }, [headerNodeList]);

  if (!rootNode) {
    return null;
  }

  return (
    <div className={styles.toc}>
      <header>TABLE OF CONTENTS</header>
      <PostTOCItem headerNode={rootNode} active={focusedHeaderIndex} windowInnerHeightRef={windowInnerHeight} />
    </div>
  );
}
