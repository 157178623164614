import cloneDeep from 'lodash/cloneDeep';

export interface HeaderNode {
  title: string;
  tagPriority: number;
  id: string;
  index: number;
  children: HeaderNode[];
}

export const TOC_ROOT_ID = '-1';
export const TOC_INTRODUCTION_ID = '-2';

/**
 * 깊이우선탐색을 통해 자료구조를 형성한다.
 * TOC는 root부터 recursive하게 자식노드를 표기하면 된다.
 * ex)         root
 *          h2       h2
 *        h3 h3     h3 h3
 *     h5 h5    ...      h6
 */
export const makeHeaderTree = (headerNodeList: HeaderNode[]) => {
  // 객체간의 참조가 이루어져야 하므로 리스트를 복사한다.
  const copiedList = cloneDeep(headerNodeList);

  const stack: HeaderNode[] = [
    {
      title: 'ROOT',
      tagPriority: 0,
      id: TOC_ROOT_ID,
      index: -1,
      children: [
        {
          title: 'introduction',
          tagPriority: 1,
          id: TOC_INTRODUCTION_ID,
          index: 0,
          children: [],
        },
      ],
    },
  ];

  copiedList.forEach(headerNode => {
    while (stack[stack.length - 1].tagPriority >= headerNode.tagPriority) {
      stack.pop();
    }
    stack[stack.length - 1].children.push(headerNode);
    stack.push(headerNode);
  });

  return stack[0];
};

export function observeElement(element: HTMLElement, callback: () => void) {
  //viewport 기준으로 20~30%영역을 진입할때 테이블을 갱신한다.
  const options = {
    root: null,
    rootMargin: '-20% 0% -70% 0%',
  };
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        callback();
      }
    });
  }, options);

  observer.observe(element);
  return observer;
}
