import cn from 'classnames';
import * as styles from './styles/FacebookButton.module.scss';

interface Props {
  variant: FacebookButtonVariant;
  onClick: () => void;
}

export default function FacebookButton({ onClick, variant }: Props) {
  return (
    <button className={cn(styles.facebook, { [styles.share]: variant === 'share' })} onClick={onClick}>
      Facebook 공유하기
    </button>
  );
}

type FacebookButtonVariant = 'share';
