import cn from 'classnames';
import * as styles from './styles/KakaoButton.module.scss';

interface Props {
  variant: KakaoButtonVariant;
  onClick: () => void;
}

export default function KakaoButton({ variant, onClick }: Props) {
  return (
    <button className={cn(styles.kakao, { [styles.share]: variant === 'share' })} onClick={onClick}>
      카카오톡 공유하기
    </button>
  );
}

type KakaoButtonVariant = 'share';
