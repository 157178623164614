import cn from 'classnames';
import { RefObject } from 'react';
import { HeaderNode } from 'src/utils/toc';

import { TOC_INTRODUCTION_ID } from 'src/utils/toc';
import * as styles from './styles/PostTOCItem.module.scss';

interface Props {
  headerNode: HeaderNode;
  active: number;
  windowInnerHeightRef: RefObject<number>;
}

export function PostTOCItem({ headerNode, active, windowInnerHeightRef }: Props) {
  const { title, tagPriority, children, index, id } = headerNode;

  /**
   * #을 사용하는 것과 이벤트 핸들러를 사용하는 것 중 이벤트 핸들러를 사용
   * #을 사용할 경우 네비바에 가려지거나, 공유할때 의도치 않은 부분부터 먼저 보여주게 될 수 있음
   * #을 사용하면 히스토리가 누적되서 불편한점이 있음
   */
  const onClickTocItem = () => {
    if (id === TOC_INTRODUCTION_ID) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      return;
    }

    const bias = (windowInnerHeightRef.current ?? 0) * 0.2;
    const relativeTop = document.getElementById(id)?.getBoundingClientRect().top ?? 0;
    const top = relativeTop + window.scrollY - bias;

    window.scrollTo({
      top,
      behavior: 'smooth',
    });
  };

  /**
   * root 인경우 제목을 노출시키지 않고 왼쪽에 패딩이 없어야 한다.
   */
  return (
    <ul className={styles.list}>
      {tagPriority !== 0 && (
        <a
          className={cn(styles.title, {
            [styles.active]: index === active,
          })}
          onClick={onClickTocItem}
          data-testid={index === active ? 'toc-item-focused' : 'toc-item'}
        >
          {title}
        </a>
      )}
      {children.map((child, index) => (
        <li key={`${title}-${index}`} className={tagPriority !== 0 ? styles.padding : ''}>
          <PostTOCItem headerNode={child} active={active} windowInnerHeightRef={windowInnerHeightRef} />
        </li>
      ))}
    </ul>
  );
}
