import Divider from 'src/components/common/Divider';
import PostDate from 'src/components/post/PostDate';
import PostReadingTime from 'src/components/post/PostReadingTime';
import { useAuthor } from 'src/hooks/useAuthor';
import * as styles from './styles/PostAuthor.module.scss';

interface Props {
  author: string;
  date: string;
  readingTime: number;
}

export default function PostAuthor({ author, date, readingTime }: Props) {
  const { name, thumbnail } = useAuthor(author);
  return (
    <div className={styles.author}>
      <div>
        <img src={thumbnail} />
      </div>
      <div>
        <p>{name}</p>
        <p>
          <PostDate date={date} />
          <Divider />
          <PostReadingTime minutes={readingTime} />
        </p>
      </div>
    </div>
  );
}
