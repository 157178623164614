import { HOST_NAME, KAKAO_JAVASCRIPT_KEY } from 'src/constant';
import isClient from 'src/utils/client';

interface Params {
  /** 글 제목 */
  title: string;
  /** 글 설명 */
  description: string;
  /** 글 서브패스, "/"는 붙이지 않는다. */
  path: string;
  /** 작성자 이름*/
  profileName: string;
  /** 작성자 이미지 */
  profileImageUrl: string;
  /** 메인 썸네일 이미지 */
  thumbnailImageUrl: string;
}

function kakaoInitAndValidate() {
  if (!window.Kakao.isInitialized()) {
    window.Kakao.init(KAKAO_JAVASCRIPT_KEY);

    if (!window.Kakao.isInitialized()) {
      //키 값을 통해 초기화 하기때문에 정상적으로 초기화가 되었는지 체크가 필요하다.
      throw 'Initialization Error!';
    }
  }
}

export default function kakaoTalkShare({
  title = 'Kakao Entertainment',
  description = '카카오엔터테인먼트 FE 기술블로그',
  path = '',
  profileName,
  profileImageUrl,
  thumbnailImageUrl,
}: Params) {
  if (!isClient()) {
    /**
     * client side에서만 작동하는 코드이다.
     */
    return;
  }

  if (!window.Kakao) {
    (async function () {
      await import('src/sdk/kakaoSDK');
      kakaoTalkShare({ title, description, path, profileImageUrl, profileName, thumbnailImageUrl });
    })();
    return;
  }

  try {
    kakaoInitAndValidate();

    window.Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title,
        description,
        imageUrl: `${HOST_NAME}${thumbnailImageUrl}`,
        imageWidth: 400,
        imageHeight: 225,
        link: {
          mobileWebUrl: `${HOST_NAME}${path}`,
          webUrl: `${HOST_NAME}${path}`,
        },
      },
      itemContent: {
        profileText: profileName,
        profileImageUrl: `${HOST_NAME}${profileImageUrl}`,
      },
      buttons: [
        {
          title: '자세히보기',
          link: {
            mobileWebUrl: `${HOST_NAME}${path}`,
            webUrl: `${HOST_NAME}${path}`,
          },
        },
      ],
    });
  } catch {
    // TODO 에러핸들링 로직 필요할 듯
  }
}
