import { noop } from 'lodash';
import { FACEBOOK_APP_ID, FACEBOOK_APP_VERSION, HOST_NAME } from 'src/constant';
import isClient from 'src/utils/client';

interface Params {
  path: string;
}

export default function facebookShare({ path }: Params) {
  if (!isClient()) {
    return;
  }

  if (!window.FB) {
    (async function () {
      await import('src/sdk/facebookSDK');
      facebookShare({ path });
    })();
    return;
  }

  window.FB.init({
    appId: FACEBOOK_APP_ID,
    autoLogAppEvents: true,
    xfbml: true,
    version: FACEBOOK_APP_VERSION,
  });

  window.FB.ui(
    {
      method: 'share',
      href: `${HOST_NAME}/${path}`,
    },
    noop
  );
}
