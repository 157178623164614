import InnerHtml from 'dangerously-set-html-content';
import isArray from 'lodash/isArray';
import { useEffect, useState, createElement } from 'react';
import rehypeParse from 'rehype-parse';
import rehypeReact from 'rehype-react';
import PostTOC from 'src/components/post/PostTOC';
import { HeaderNode } from 'src/utils/toc';
import { unified } from 'unified';
import IframeOpener from './customEffectors/IframeOpener';
import Script from './customEffectors/Script';
import SplitDiff from './customEffectors/SplitDiff';
import * as styles from './styles/PostContainer.module.scss';

interface RehypedContent extends React.ReactElement {
  type: string;
  props: {
    id: string;
    children: (string | RehypedContent)[];
  };
}

interface Props {
  html: string;
  usesCustomRender: boolean;
}

function flatAllChildrenText(children: string | RehypedContent) {
  if (typeof children === 'string') {
    return [children];
  }

  if (typeof children === 'object') {
    const result: string[] = [];

    children.props.children?.forEach(child => {
      const textList = flatAllChildrenText(child);
      result.push(...textList);
    });

    return result;
  }

  return [];
}

export default function PostContainer({ html, usesCustomRender }: Props) {
  const [content, setContent] = useState(<></>);
  const [headerNodeList, setHeaderNodeList] = useState<HeaderNode[]>([]);

  useEffect(() => {
    unified()
      .use(rehypeParse, { fragment: true })
      .use(rehypeReact, {
        createElement,
        // TODO rehypeReact의 타입정의와 조금 다르므로 any처리한다.
        // rehypeReact key값은 element tagname이 되어야함 => "a", "p"...
        components: { 'split-diff': SplitDiff, 'iframe-opener': IframeOpener, script: Script } as any,
      })
      .process(html)
      .then(file => {
        const { result } = file;
        setContent(result);

        if (isRehypedContent(result)) {
          const hTagList = result.props.children.filter(
            child => typeof child !== 'string' && !!REG_HEADER_TAG.exec(child.type) && isHeaderBySharp(child)
          ) as RehypedContent[];

          const nodeList = hTagList.map((child, index) => {
            const tagPriority = Number(child.type[1]);

            const id = child.props.id;
            const title = flatAllChildrenText(child).join('');

            return {
              title,
              tagPriority,
              children: [],
              id,
              index: index + 1,
            };
          });

          setHeaderNodeList(nodeList);
        }
      });
  }, [usesCustomRender, html]);

  return (
    <div className={styles.postContainer}>
      <div className={styles.postTocWrapper}>
        <PostTOC headerNodeList={headerNodeList} />
      </div>
      <div className={styles.postContent}>{usesCustomRender ? content : <InnerHtml html={html} />}</div>
    </div>
  );
}

function isRehypedContent(content: React.ReactElement): content is RehypedContent {
  return isArray(content.props?.children);
}

function isHeaderBySharp(child: RehypedContent) {
  // 직접 h태그를 생성한 경우를 필터링한다.
  // #으로 생성한 h태그의 경우 앞에 a태그가 붙는다.
  return typeof child.props.children[0] === 'object' && child.props.children[0].type === 'a';
}

const REG_HEADER_TAG = new RegExp(/^h[1-6]$/);
