import { IGatsbyImageData } from 'gatsby-plugin-image';
import FacebookButton from 'src/components/common/FacebookButton';
import KakaoButton from 'src/components/common/KakaoButton';
import { useAuthor } from 'src/hooks/useAuthor';
import facebookShare from 'src/utils/share/facebookShare';
import kakaoTalkShare from 'src/utils/share/kakaoTalkShare';

import * as styles from './styles/PostFooter.module.scss';

interface Props {
  title: string;
  description: string;
  author: string;
  slug: string;
  thumbnailImage?: IGatsbyImageData;
}

export default function PostFooter({ title, description, author, slug, thumbnailImage }: Props) {
  const { name: profileName, thumbnail: profileImageUrl } = useAuthor(author);

  return (
    <div className={styles.footer}>
      <KakaoButton
        variant="share"
        onClick={() =>
          kakaoTalkShare({
            title,
            description,
            profileName,
            profileImageUrl,
            path: slug,
            thumbnailImageUrl: thumbnailImage?.images.fallback?.src ?? '',
          })
        }
      />
      <FacebookButton
        variant="share"
        onClick={() => {
          facebookShare({
            path: slug,
          });
        }}
      />
    </div>
  );
}
