/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useEffect } from 'react';

import 'src/styles/code.scss';
import 'src/styles/post.scss';

import Bio from 'src/components/common/Bio';
import Elements from 'src/components/common/Elements';
import Layout from 'src/components/common/Layout';
import PostAuthor from 'src/components/post/PostAuthor';
import PostFooter from 'src/components/post/PostFooter';
import { useAuthor } from 'src/hooks/useAuthor';
import useSiteMetadata from 'src/hooks/useSiteMetadata';
import thumbnail from 'src/images/kakaoEnt.png';
import Meta from '../components/common/Meta';
import PostContainer from '../components/post/PostContainer';
import PostNavigator from '../components/post/PostNavigator';
import PostTitle from '../components/post/PostTitle';
import * as ScrollManager from '../utils/scroll';

interface Context {
  id: string;
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
  };
}

export interface BlogPageContext {
  id: string;
  slug: string;
  previous: Context;
  next: Context;
}

export default function BlogPostTemplate({
  data,
  pageContext,
}: PageProps<Queries.BlogPostBySlugQuery, BlogPageContext>) {
  useEffect(() => {
    ScrollManager.init();
    return () => {
      ScrollManager.destroy();
    };
  }, []);

  const post = data.markdownRemark!;

  const { title: postTitle, author, date, description, usesCustomRender } = post.frontmatter;

  const {
    frontmatter: { thumbnailImage },
    fields: { thumbnailImageDefault },
  } = post;

  const image =
    getImage(thumbnailImage?.childImageSharp?.gatsbyImageData ?? null) ??
    getImage(thumbnailImageDefault?.childImageSharp?.gatsbyImageData ?? null);

  return (
    <Layout>
      <article>
        <header className="header">
          <PostTitle title={postTitle ?? ''} />
          <PostAuthor author={author} date={date} readingTime={post.fields.readingTime ?? 0} />
        </header>
        <section>
          <PostContainer html={post.html ?? ''} usesCustomRender={usesCustomRender ?? false} />
        </section>
        <Elements.Hr />
        <Bio author={author} />
      </article>
      <PostFooter
        title={postTitle}
        description={description ?? ''}
        author={author}
        slug={pageContext.slug}
        thumbnailImage={image}
      />
      <PostNavigator pageContext={pageContext} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        thumbnailImageDefault {
          childImageSharp {
            gatsbyImageData
          }
        }
        readingTime
      }
      id
      excerpt(pruneLength: 120)
      html
      frontmatter {
        title
        description
        date(formatString: "YYYY.MM.DD")
        author
        usesCustomRender
        thumbnailImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export const Head = ({ data }: PageProps<Queries.BlogPostBySlugQuery, BlogPageContext>) => {
  const post = data.markdownRemark!.frontmatter;
  const author = useAuthor(post.author);
  const sitemeta = useSiteMetadata();

  const thumbnailImage = data.markdownRemark?.frontmatter.thumbnailImage;
  const thumbnailImageDefault = data.markdownRemark!.fields.thumbnailImageDefault;
  const imageData =
    getImage(thumbnailImage?.childImageSharp?.gatsbyImageData ?? null) ??
    getImage(thumbnailImageDefault?.childImageSharp?.gatsbyImageData ?? null);
  const imageLink = imageData?.images.fallback?.src;

  return (
    <>
      <Meta title={post.title} description={post.description ?? ''} thumbnailImagePath={imageLink} />
      <script type="application/ld+json">
        {`
   {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": "${post.title}",
    "datePublished": "${new Date(post.date.replace(/\./g, '/')).toISOString()}",
    "author": {
      "name": "${author.name}"
    },
    "image": ["${sitemeta.siteUrl}${thumbnail}"]
  }
   `}
      </script>
    </>
  );
};
